.custom-file-input {
  color: transparent;
}
/* .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
} */
.custom-file-input::before {
  content: 'Seleccione archivo';
  color: white;
  display: inline-block;
  background-color: #000000;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: #000000; 
}
